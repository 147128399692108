import React, { useContext, useEffect } from "react"
import { toast } from "react-toastify"

import { Button, Link, styled } from "@ioxio-priv/dataspace-ui"
import Box from "@mui/material/Box"

import { MetaData } from "@/components/MetaData"
import Text from "@/components/Text"
import { Breadcrumbs, BreadcrumbsNames } from "@/constants/breadcrumbs"
import { labels } from "@/constants/labels"
import ROUTES from "@/constants/routes"
import InitialLoading from "@/containers/InitialLoading"
import { AuthContext } from "@/context/AuthContext"
import { Icons } from "@/dsIcon"
import useCodeExchange from "@/hooks/useCodeExchange"
import useLayoutOpts from "@/hooks/useLayoutOpts"
import AuthAPI from "@/services/authAPI"
import ConsentAPI from "@/services/consentAPI"
import { config } from "@/settings"
import { getQueryParams } from "@/utilities"

function TermsOfService({ onAgree, onCancel }) {
  return (
    <TermsOfServiceBox>
      <MetaData {...labels.meta.terms} />
      <Breadcrumbs current={BreadcrumbsNames.TERMS_OF_SERVICE} />
      <TextBox>
        <Text>
          Welcome! As a new user you need to first agree to the{" "}
          <Link rel="noreferrer" target="_blank" href={config.termsOfService}>
            {config.termsOfServiceName}
          </Link>{" "}
          as well as our{" "}
          <Link rel="noreferrer" target="_blank" href={config.privacyPolicy}>
            Privacy Policy
          </Link>
        </Text>
        <Text>Please read them carefully before agreeing</Text>
      </TextBox>
      <ButtonBox>
        <Button
          icon={Icons.cancel}
          color={"secondary"}
          onClick={onCancel}
          label={"Cancel"}
        />
        <Button
          icon={Icons.success}
          color={"primary"}
          onClick={onAgree}
          label={"I agree"}
        />
      </ButtonBox>
    </TermsOfServiceBox>
  )
}

export default function CodeExchange({ history }) {
  const { SOURCES_AVAILABLE, START_LOGOUT, LOGIN } = ROUTES
  // remove navbar and footer
  useLayoutOpts(false, false)

  // get query params from URL
  const queryParams = getQueryParams(["code", "state", "nonce"])

  // perform code exchange
  const { loading, termsAccepted, afterLoginRedirect } = useCodeExchange(
    history,
    queryParams
  )

  const authContext = useContext(AuthContext)

  useEffect(() => {
    ;(async () => {
      if (termsAccepted) {
        if (!authContext.isLoggedIn()) {
          const { ok, data } = await AuthAPI.getUserData()
          if (ok && data.loggedIn) {
            authContext.setUser(data)
          } else {
            history.push({
              pathname: LOGIN,
              state: { error: "Authentication failed" },
            })
          }
        }

        const redirectTo = afterLoginRedirect ? afterLoginRedirect : SOURCES_AVAILABLE
        if (redirectTo.startsWith("http://") || redirectTo.startsWith("https://")) {
          // Redirects to other components
          window.location.href = afterLoginRedirect
        } else {
          // Redirects within this router
          history.push(redirectTo)
        }
      }
    })()
  }, [authContext, termsAccepted, afterLoginRedirect, history])

  // Agree to terms, redirects to /definitions
  async function onAgree() {
    const { ok, status, error } = await ConsentAPI.acceptTerms()
    if (ok) {
      const { ok, data } = await AuthAPI.getUserData()
      if (ok && data.loggedIn && data.termsAccepted) {
        authContext.setUser(data)
        history.push(afterLoginRedirect ? afterLoginRedirect : SOURCES_AVAILABLE)
      } else {
        history.push({
          pathname: LOGIN,
          state: { error: "Authentication failed" },
        })
      }
    } else {
      if (status === 409) {
        history.push(afterLoginRedirect ? afterLoginRedirect : SOURCES_AVAILABLE)
      } else {
        toast.error(error)
      }
    }
  }

  // Reject terms
  function onCancel() {
    history.push(START_LOGOUT)
  }

  function renderTermsComponent() {
    if (!loading) {
      if (!termsAccepted) {
        return <TermsOfService onAgree={onAgree} onCancel={onCancel} />
      }
    }
    return <InitialLoading />
  }

  return renderTermsComponent()
}

const TermsOfServiceBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 98%;
`

const TextBox = styled(Box)`
  padding-top: 2rem;
`

const ButtonBox = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin: 1rem 0;
`
