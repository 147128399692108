export const Icons = {
  cached: "cached",
  cancel: "cancel",
  delete: "delete",
  edit: "edit",
  eye: "eye",
  save: "save",
  add: "add",
  success: "success",
  refresh: "refresh",
  addCircle: "add-circle",
  exit: "exit",
  send: "send",
  checked: "success",
  chevronRight: "chevron-right",
  search: "search",
  arrowForward: "arrow-forward",
  copy: "copy",
  arrowUp: "arrow-up",
  arrowDown: "arrow-down",
  arrowReturn: "arrow-return",
  eyeClosed: "eye-closed",
  login: "login",
  signUp: "sign-up",
}
