import { baseConfig, baseDsConfig, runtimeSettings } from "./base"

// Dataspace specific configuration
export const dsConfig = {
  ...baseDsConfig,
}

// Application specific configuration
const config = {
  ...baseConfig,
}

export { config, runtimeSettings as rs }
