import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"

import { Button, InfoCard, Link } from "@ioxio-priv/dataspace-ui"
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import Grid from "@mui/material/Grid"
import { styled } from "@mui/material/styles"
import Typography from "@mui/material/Typography"

import AddAllowedGroupModal from "@/components/EditDataSourceForm/AddAllowedGroupModal"
import RemoveAllowedGroupModal from "@/components/EditDataSourceForm/RemoveAllowedGroupModal"
import Spinner from "@/components/Spinner"
import DataSourceAPI from "@/services/dataSourceAPI"
import { config } from "@/settings"

export default function AllowedGroups({ dsi }) {
  const [isAddOpen, setAddOpen] = useState(false)
  const [groupToRemove, setGroupToRemove] = useState("")
  const isRemoveOpen = groupToRemove !== ""

  const [allowedGroups, setAllowedGroups] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const { ok, data, error } = await DataSourceAPI.listAllowedGroups(dsi)
      if (ok) {
        setAllowedGroups(sortGroups(data.groups))
        setLoading(false)
      } else {
        toast.error(error)
        setLoading(false)
      }
    })()
  }, [])

  function sortGroups(groups) {
    return typeof groups[0] === "string"
      ? groups.sort()
      : groups.sort((a, b) => a.groupName.localeCompare(b.groupName))
  }

  function groupInfo(group) {
    if (group.technicalContact) {
      return `${group.groupName} (${group.technicalContact})`
    } else {
      return group.groupName || group
    }
  }

  async function addGroup(group) {
    const { ok, data, error } = await DataSourceAPI.addAllowedGroup({ dsi, group })
    if (ok) {
      setAllowedGroups(sortGroups(data.groups))
    }
    return { ok, error }
  }

  async function removeGroup(group) {
    const { ok, data, error } = await DataSourceAPI.removeAllowedGroup(
      dsi,
      groupToRemove
    )
    if (ok) {
      setAllowedGroups(sortGroups(data.groups))
      toast.success(
        `Group ${group.groupName || group} can no longer access this data source`
      )
    } else {
      toast.error(error)
    }
    return { ok }
  }

  return (
    <div>
      <Wrapper>
        <InfoCard>
          <p>Add or remove groups that have access to your data source.</p>
          <p>
            The API tokens can be verified using{" "}
            <Link target="_blank" rel="noreferrer" href={config.jwksUrl}>
              the Dataspace's JWKS
            </Link>
            .
          </p>
          <p>
            <Link target="_blank" rel="noreferrer" href={config.tokenVerificationCode}>
              See example
            </Link>
            .
          </p>
        </InfoCard>
        <Header variant={"h4"}>
          <span>Allowed groups</span>
          <Button
            baseProps={{
              "data-testid": "add-group-btn",
            }}
            icon="add"
            color="success"
            onClick={() => {
              setAddOpen(true)
            }}
          >
            Add
          </Button>
        </Header>
        {loading ? (
          <Spinner />
        ) : (
          <Grid>
            <BodyWrapper>
              {allowedGroups.length === 0 ? (
                <Body>
                  <BodyItem>No groups have access to this data source yet.</BodyItem>
                </Body>
              ) : (
                allowedGroups.map((group) => {
                  return (
                    <Body key={group.groupName || group}>
                      <BodyItem
                        data-testid={`allowed-group-${group.groupName || group}`}
                      >
                        {groupInfo(group)}
                      </BodyItem>
                      <BodyActionIcons>
                        <DeleteOutlinedIcon
                          aria-label="remove group"
                          data-testid={`remove-${group.groupName || group}-btn`}
                          role="button"
                          tabIndex={0}
                          variant="outlined"
                          onClick={() => setGroupToRemove(group.groupName || group)}
                        />
                      </BodyActionIcons>
                    </Body>
                  )
                })
              )}
            </BodyWrapper>
          </Grid>
        )}
      </Wrapper>

      <AddAllowedGroupModal
        isOpen={isAddOpen}
        closeModal={() => {
          setAddOpen(false)
        }}
        asyncOnSubmit={addGroup}
      />
      <RemoveAllowedGroupModal
        group={groupToRemove}
        isOpen={isRemoveOpen}
        closeModal={() => setGroupToRemove("")}
        asyncOnSubmit={removeGroup}
      />
    </div>
  )
}

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1.5rem 0;
`

const Header = styled(Typography)`
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const BodyWrapper = styled(Grid)`
  border: 1px solid ${(p) => p.theme.palette.secondary.light};
  padding: 0;
  margin: 0;
`

const Body = styled(Grid)`
  display: flex;
  padding: 0.5rem 1rem;
  min-height: 2.5rem;
  width: 100%;
  background: ${(p) => p.theme.palette.neutral.light};
  border-bottom: 1px solid ${(p) => p.theme.palette.neutral.main};

  &:last-of-type {
    border-bottom: none;
  }
`

const BodyItem = styled("p")`
  font-size: 1rem;
  font-style: normal;
  line-height: 150%;
  font-weight: 400;
  word-break: break-word;
  padding-right: 1rem;

  &.role {
    text-transform: capitalize;
  }
`

const BodyActionIcons = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  justify-self: flex-end;
  margin-left: auto;
  gap: 1.5rem;

  svg {
    cursor: pointer;
    width: 1.25rem;
    height: 1.25rem;
  }

  svg:focus {
    outline: 2px solid ${(p) => p.theme.palette.success.main};
    border-radius: 0.3125rem;
  }
`
