import React from "react"

import Grid from "@mui/material/Grid"

import { MainContentBox } from "@/commonStyles"
import { ApplicationsHelp } from "@/components/ApplicationsHelp"
import Header from "@/components/Header"
import { MetaData } from "@/components/MetaData"
import ResourcesBox from "@/components/ResourcesBox"
import { Breadcrumbs, BreadcrumbsNames, BreadcrumbsPath } from "@/constants/breadcrumbs"
import useLayoutOpts from "@/hooks/useLayoutOpts"
import { config } from "@/settings"

export default function ApplicationWrapper({
  children,
  belowHeader,
  breadCrumbs,
  title,
  meta,
  editPage = false,
}) {
  // render navbar and footer
  useLayoutOpts(true, true)

  const resources = (
    <ResourcesBox
      links={[
        {
          text: "Learn how to integrate an application",
          link: config.guideApplications,
        },
        {
          text: "Example implementation of an application",
          link: config.exampleApplication,
        },
      ]}
    />
  )

  return (
    <MainContentBox data-testid={config.externalAppManagement ? "externalApp" : null}>
      <MetaData {...meta} />
      <Grid container columnSpacing={"1.5rem"}>
        <Grid item xs={12} md={12} lg={8}>
          <Header
            breadCrumb={
              <Breadcrumbs
                current={breadCrumbs || BreadcrumbsNames.APPLICATIONS}
                paths={breadCrumbs ? [BreadcrumbsPath.APPLICATIONS] : []}
              />
            }
            title={title}
          >
            {!editPage && <ApplicationsHelp />}
          </Header>
          {belowHeader}
          <Grid item display={{ lg: "none" }}>
            {resources}
          </Grid>
          {children}
        </Grid>
        <Grid item lg={4} mt="7.2rem" display={{ xs: "none", lg: "block" }}>
          {resources}
        </Grid>
      </Grid>
    </MainContentBox>
  )
}
