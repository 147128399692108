import React, { useContext } from "react"
import { ToastContainer } from "react-toastify"

import { useDsConfig } from "@ioxio-priv/dataspace-ui"
import { Layout as LayoutSvelte, setUiConf } from "@ioxio-priv/dataspace-ui-svelte"
import { reactify } from "svelte-preprocess-react"

import { config, dsConfig, rs } from "@/settings"

import { AuthContext } from "../context/AuthContext"

import "$dsui/styles/_init.scss"

const SvelteLayout = reactify(LayoutSvelte)

export default function Layout({ children }) {
  useDsConfig(dsConfig)

  const user = useContext(AuthContext).user

  setUiConf(rs, "developer-portal", {
    footer: {
      externalLinks: dsConfig.footerExternalLinks,
      internalLinks: Object.entries(dsConfig.components).map(([k, v]) => ({
        name: k,
        url: v,
      })),
    },
    dsBaseDomain: config.dataspaceDomain,
  })

  return (
    <SvelteLayout user={user}>
      <ToastContainer />
      {children}
    </SvelteLayout>
  )
}
