import React from "react"

import { IconButton, InputField, Tooltip } from "@ioxio-priv/dataspace-ui"
import { styled } from "@mui/material/styles"
import Typography from "@mui/material/Typography"

import FormRadioGroup from "@/components/FormRadioGroup"
import { labels } from "@/constants/labels"
import { Icons } from "@/dsIcon"
import { copyToClipboard } from "@/utilities"

export default function SourceState({ form, source, previewToken, refreshToken }) {
  return (
    <div>
      <Header>
        <span>Visibility</span>
        <Tooltip>Listing state of the data source.</Tooltip>
      </Header>
      <FormRadioGroup
        form={form}
        name="state"
        baseProps={{
          "data-testid": "state",
        }}
        options={[
          {
            label: "Private",
            description: `Suitable only for your own testing. Will not be listed on the available data sources listing and be available only with the <b>X-Preview-Token</b> header${
              source ? " as the source <b>" + source + "</b>" : ""
            }.`,
            value: "private",
            extraItem: (
              <InputField
                value={previewToken}
                id={"previewToken"}
                label={labels.dataSource.fields.previewToken.label}
                name={"previewToken"}
                readonly
                tooltipText={labels.dataSource.fields.previewToken.tooltipText}
              >
                <IconButton
                  icon={Icons.copy}
                  title={"Copy"}
                  color={"secondary"}
                  variant={"outlined"}
                  onClick={() =>
                    copyToClipboard(
                      previewToken,
                      "Preview token has been copied to the clipboard"
                    )
                  }
                />
                <IconButton
                  icon={Icons.cached}
                  title={"Refresh"}
                  color={"secondary"}
                  variant={"outlined"}
                  onClick={refreshToken}
                />
              </InputField>
            ),
          },
          {
            label: "Unlisted",
            description: `Will not be listed on the available data sources listing, but be available for those who know it exists${
              source ? " as the source <b>" + source + "</b>" : ""
            }.`,
            value: "unlisted",
          },
          {
            label: "Published",
            description: `Will be listed on the available data sources listing and available to everyone${
              source ? " as the source <b>" + source + "</b>" : ""
            }.`,
            value: "published",
          },
        ]}
      />
    </div>
  )
}

const Header = styled(Typography)`
  font-weight: 500;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`
