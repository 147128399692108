import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"

import { styled, Submit, WarningCard } from "@ioxio-priv/dataspace-ui"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

import { ReactComponent as LoginImg } from "@/assets/resources/loginImg.svg"
import { MailLink, MainContentBox } from "@/commonStyles"
import Heading from "@/components/Heading"
import { InlineLink } from "@/components/InlineLink"
import { MetaData } from "@/components/MetaData"
import { Breadcrumbs, BreadcrumbsNames } from "@/constants/breadcrumbs"
import { labels } from "@/constants/labels"
import ROUTES from "@/constants/routes"
import { Icons } from "@/dsIcon"
import useLayoutOpts from "@/hooks/useLayoutOpts"
import useToast from "@/hooks/useToast"
import useUserData from "@/hooks/useUserData"
import AuthAPI from "@/services/authAPI"
import { config, dsConfig } from "@/settings"
import { getQueryParams } from "@/utilities"

export default function Login({ history, location }) {
  const { SOURCES_AVAILABLE } = ROUTES
  const queryParams = getQueryParams(["after_login", "search"])
  let data = {}

  if (queryParams.after_login) {
    data = { afterLoginRedirect: `${queryParams.after_login}` }
    if (queryParams.search) {
      data.afterLoginRedirect += `?search=${queryParams?.search}`
    }
  }

  // render footer and remove navbar
  useLayoutOpts(true, true)

  const { meRequestData } = useUserData()
  const [loading, setLoading] = useState(true)

  // If there is a loggedIn user, forward to 'available sources'
  useEffect(() => {
    if (meRequestData) {
      history.push({
        pathname: SOURCES_AVAILABLE,
      })
    }
    setLoading(false)
  }, [history, meRequestData])

  // Initialize toasts and render any in the state
  useToast(location.state)

  // receives redirectUri, and redirects to login portal
  function onLoginRequestFinish(res, history) {
    const { ok, data, status, error } = res
    if (ok) {
      window.location.href = data.redirectUri
    } else {
      if (status === 403) {
        history.push({
          pathname: SOURCES_AVAILABLE,
        })
      } else {
        toast.error(error)
      }
    }
  }

  return (
    !loading && (
      <MainContentBox>
        <MetaData {...labels.meta.login} />
        <Breadcrumbs current={BreadcrumbsNames.LOGIN} />
        <Heading csx={headingStyles} baseProps={{ "data-testid": "heading" }}>
          Log in
        </Heading>
        <Grid container columnSpacing={"1.5rem"}>
          <TextGrid item xs={12} lg={8}>
            <Box>
              <p>
                Logging in is required to access the full functionality of{" "}
                <b>{dsConfig.dataspaceVariant}</b>, such as bringing your data available
                to others, and using it in your applications.
              </p>
            </Box>
            <HorizontalLine />
            {config.inviteOnly && (
              <WarningWrapper>
                <WarningCard>
                  <Typography>
                    This dataspace is in invite-only mode. If you do not have a user
                    account, but believe you should have one, please contact support at{" "}
                    <MailLink href={`mailto:${config.supportEmail}`}>
                      {config.supportEmail}
                    </MailLink>
                  </Typography>
                </WarningCard>
              </WarningWrapper>
            )}
            <Buttons>
              <Submit
                baseProps={{
                  "data-testid": "login",
                  size: "large",
                }}
                asyncOnClick={{
                  asyncFn: AuthAPI.startLogin.bind(AuthAPI, data),
                  onAsyncFinish: (res) => onLoginRequestFinish(res, history),
                  onValidate: () => {
                    return {}
                  },
                  loadingText: "Logging in...",
                  successText: "Redirecting!",
                }}
                icon={Icons.login}
              >
                Log in
              </Submit>
              <Submit
                baseProps={{
                  "data-testid": "signUp",
                  size: "large",
                }}
                color="secondary"
                asyncOnClick={{
                  asyncFn: AuthAPI.startLogin.bind(AuthAPI, data),
                  onAsyncFinish: (res) => onLoginRequestFinish(res, history),
                  onValidate: () => {
                    return {}
                  },
                  loadingText: "Redirecting!",
                  successText: "Redirecting!",
                }}
                icon={Icons.signUp}
              >
                Sign up
              </Submit>
            </Buttons>
            <AfterLoginText variant="body2">
              By signing up or logging in you agree to the{" "}
              <InlineLink rel="noreferrer" target="_blank" href={config.termsOfService}>
                {config.termsOfServiceName}
              </InlineLink>{" "}
              as well as our{" "}
              <InlineLink rel="noreferrer" target="_blank" href={config.privacyPolicy}>
                Privacy Policy
              </InlineLink>
              .
            </AfterLoginText>
          </TextGrid>
          <Grid item xs={12} md={6} lg={4}>
            <LoginImg />
          </Grid>
        </Grid>
      </MainContentBox>
    )
  )
}

const headingStyles = { mt: "1rem", mb: "1.5rem" }

const WarningWrapper = styled("div")`
  margin: 0.5rem auto;
`

const Buttons = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
`

const TextGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  > button {
    align-self: flex-start;

    ${({ theme }) => theme.breakpoints.down("md")} {
      align-self: center;
    }
  }
`

const HorizontalLine = styled("hr")`
  border-top: 0;
`
const AfterLoginText = styled(Typography)`
  margin: 1.5rem 0;
`
