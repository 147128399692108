import React from "react"

import { Icon, IconLink, InfoCard, LinkButton, styled } from "@ioxio-priv/dataspace-ui"
import { useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import Typography from "@mui/material/Typography"

import { ReactComponent as DefinitionIcon } from "@/assets/definition-icon.svg"
import { ReactComponent as SourceIcon } from "@/assets/source-icon.svg"
import { Icons } from "@/dsIcon"
import { parseDSI } from "@/utilities"

export function GroupsWithAccessToDS({ sources, definitions }) {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const dsiByGroup = sources.reduce((acc, val) => {
    if (acc[val.group]) {
      acc[val.group].push(parseDSI(val.dsi))
    } else {
      acc[val.group] = [parseDSI(val.dsi)]
    }
    return acc
  }, {})

  // sort by title, then by source
  for (const group of Object.keys(dsiByGroup)) {
    dsiByGroup[group] = dsiByGroup[group].sort((a, b) => {
      const x = definitions[a.definition].summary + a.source
      const y = definitions[b.definition].summary + b.source
      if (x === y) {
        return 0
      }
      return x > y ? 1 : -1
    })
  }

  function makeViewUrl(group, dsi) {
    let url = `/access-control-keys/${group}?`
    url += new URLSearchParams({ dsi }).toString()
    return url
  }

  return (
    <div>
      {sources.length === 0 && (
        <NoGroups>
          <InfoCard>
            None of your groups have been given access to any data sources yet. You can
            request access to data sources by contacting the data source developers
            directly.
          </InfoCard>
        </NoGroups>
      )}
      {Object.keys(dsiByGroup)
        .sort()
        .map((group) => (
          <div key={group}>
            <GroupTitle variant={"h4"}>
              <Icon name={"admin"} variant={"outlined"} />
              <GroupLink href={`/groups/edit/${group}`}>
                {group}
                <Icon name={"launch"} />
              </GroupLink>
            </GroupTitle>
            {dsiByGroup[group].map((dsi) => (
              <SourceWrapper key={dsi.dsi}>
                <Source>
                  <Typography variant={"h4"}>
                    {definitions[dsi.definition].summary}
                  </Typography>
                  <Row title="Data product definition">
                    <DefinitionIcon width={"1rem"} />
                    {dsi.definition}
                  </Row>
                  <Row title="Source">
                    <SourceIcon width={"1rem"} />
                    {dsi.source}
                  </Row>
                </Source>
                {smallScreen ? (
                  <IconLink
                    href={makeViewUrl(group, dsi.dsi)}
                    icon={Icons.eye}
                    iconVariant={"outlined"}
                  />
                ) : (
                  <LinkButton
                    href={makeViewUrl(group, dsi.dsi)}
                    icon={Icons.eye}
                    iconVariant={"outlined"}
                    baseProps={{
                      "data-testid": group + "-" + dsi.source + "-" + dsi.definition,
                    }}
                  >
                    View
                  </LinkButton>
                )}
              </SourceWrapper>
            ))}
          </div>
        ))}
    </div>
  )
}

const GroupTitle = styled(Typography)`
  margin: 1.5rem 0 0 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const SourceWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  border: 1px solid ${(p) => p.theme.palette.secondary.light};
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  border-radius: 0.3125rem;
  padding: 1rem;
  margin: 1rem 0;

  a {
    flex-shrink: 0;
    align-self: flex-start;
  }
`

const Source = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const Row = styled("div")`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`

const GroupLink = styled("a")`
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    text-decoration: underline;
  }
`

const NoGroups = styled("p")`
  margin-top: 1rem;
`
